/** 
 * Module for database manipulation
 * This moddule is ment as a single entry point for interaction with the database.
 * If the parameter is offline does not go to the backend and simulates the backend logic so that 
 *  the transactions can be saved later 
*/

// Global constants
import * as con from "../../GlobalConstants"

import axios from 'axios';
import { createEditingAnnotation } from "./annotation";
import { getCurrentLine, getNewTransactionId, getRequestConfig, getSurrogateUser, getUser, isOffline, surrogateActive } from "./supportFunctions";
import { postUserActivity } from "../../GlobalFunctions";

// Interface Methods
// -----------------
export class DB {

    // Transaction Methods
    // ------------------
    static async fetchTransactions(transactionType)
    {
        // Always calls remote
        return(OnlineLogic.fetchTransactions(transactionType))
    }

    static async addTransaction(transactionType, transactionValues){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.addTransaction(transactionType, transactionValues))
    }

    static async addBulkTransactions(transactionType, transactionsValues){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.addBulkTransactions(transactionType, transactionsValues))
    }

    static async replaceTransactions(transactionType, transactionsValues){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.replaceTransactions(transactionType, transactionsValues))
    }

    static async setTransaction(transactionType, id, transactionValues){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.setTransaction(transactionType, id, transactionValues))
    }

    static async deleteTransaction(transactionType, id){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.deleteTransaction(transactionType, id))
    }

    static async deleteAllTransactions(transactionType){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.deleteAllTransactions(transactionType))
    }

    static async prepayExposure(expId, amountToPrepay, paymentMethod, prepayementRate){

        let ob = OnlineLogic
        if(isOffline())
            ob = OfflineLogic

        return(ob.prepayExposure(expId, amountToPrepay, paymentMethod, prepayementRate))

    }


    // Annotation Methods
    // -----------------
    static async fetchAnnotations(transactionType)
    {
        // Always calls remote
        return(OnlineLogic.fetchAnnotations(transactionType))
    }

    static async addAnnotation(transactionType, transId, annotation)
    {
        // Always calls remote
        return(OnlineLogic.addAnnotation(transactionType, transId, annotation))

    }

} 


// Remote Methods
class OnlineLogic{
    
    static TRANSACTION_API_MAP = {
        [con.EXPOSURES] : con.exposures_api,
        [con.COVERAGES_FWD] : con.coverages_fwd_api,
        [con.COVERAGES_SPOT] : con.coverages_spot_api,
        [con.COVERAGES_OPTION] : con.coverage_option_api,
        [con.ACCOUNTS] : con.accounts_api,
        [con.MARKET_ALERTS] : con.MARKET_ALERTS
    
    }
    
    static TRANSACTION_ANNOTATION_API_MAP = {
        [con.EXPOSURES] : con.exposures_annotation_api,
        [con.COVERAGES_FWD] : con.coverages_fwd_annotation_api,
        [con.COVERAGES_SPOT] : con.coverages_spot_annotation_api,
        [con.COVERAGES_OPTION] : con.coverages_option_annotation_api,
        [con.ACCOUNTS] : con.accounts_annotation_api
    
    }

    
    static async fetchTransactions(transactionType)
    {

        // Default Headers
        const config = getRequestConfig()   
        
        let res = null

        res = await axios.get(con.rest_base_url + this.getTransactionApi(transactionType) + con.bulk + `${getCurrentLine()}/`, config)

        return(res.data)
                
    }


    static async addTransaction(transactionType, transactionValues){

        // Default Headers
        const config = getRequestConfig()

        let data = transactionValues

        let res = await axios.post(con.rest_base_url + this.getTransactionApi(transactionType), data, config)

        await postUserActivity(`add single ${transactionType}`)

        return(res.data)
        
    }


    static async addBulkTransactions(transactionType, transactionsValues){

        await postUserActivity(`add bulk ${transactionType}`)

        // Default Headers
        const config = getRequestConfig()

        let data =  transactionsValues

        let res = await axios.patch(con.rest_base_url + this.getTransactionApi(transactionType) + con.bulk + `${getCurrentLine()}/`, data, config)



        return(res.data)
        
    }

    static async replaceTransactions(transactionType, transactionsValues){

        await postUserActivity(`replace bulk ${transactionType}`)

        // Default Headers
        const config = getRequestConfig()

        let data = transactionsValues                               

        let res = await axios.put(con.rest_base_url + this.getTransactionApi(transactionType) + con.bulk + `${getCurrentLine()}/`, data, config)

        return(res.data)
        
    }

    static async deleteAllTransactions(transactionType)
    {
        await postUserActivity(`delete all ${transactionType}`)

        // Default Headers
        const config = getRequestConfig()
            
        let res = await axios.delete(con.rest_base_url + this.getTransactionApi(transactionType) + con.bulk + `${getCurrentLine()}/`, config)

        return(res.data)

    }

    

    static async setTransaction(transactionType, id, transactionValues){

        await postUserActivity(`set single ${transactionType}`)

        // Default Headers
        const config = getRequestConfig()

        let data = {[con.DATA] : transactionValues}
            
        if(surrogateActive())
            data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]

        let res = await axios.put(con.rest_base_url + this.getTransactionApi(transactionType) + `${id}/`, data, config)

        return(res.data)
        
    }

    static async deleteTransaction(transactionType, id){

        await postUserActivity(`delete single ${transactionType}`)

        // Default Headers
        const config = getRequestConfig()

        await axios.delete(con.rest_base_url + this.getTransactionApi(transactionType) + `${id}/`, config)

        return(id)
        
    }

    static async prepayExposure(expId, amountToPrepay, paymentMethod, prepayementRate){

        await postUserActivity("prepay")

        // Default Headers
        const config = getRequestConfig()

        let data = {[con.PREPAY_METHOD] : paymentMethod,
                    [con.PREPAY_AMOUNT] : amountToPrepay,
                    [con.PREPAYMENT_RATE] : prepayementRate}
        
        if(surrogateActive())
            data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
        
        let res = await axios.put(con.rest_base_url + this.getTransactionApi(con.EXPOSURES) +  `prepay/${expId}/`,data,config)
        
        return(res.data)
                

    }


    // Annotation Methods
    // ------------------------

    static async fetchAnnotations(transactionType)
    {
        // Default Headers
        const config = getRequestConfig()

        let res = await axios.get(con.rest_base_url + this.getTransactionAnnotationApi(transactionType) + con.bulk + `${getCurrentLine()}/`, config)
  
        return(res.data)           
    }

    static async addAnnotation(transactionType, transId, annotation)
    {
        // Default Headers
        const config = getRequestConfig()

        let finalAnnotation = {
            ...annotation,
            [con.USER] : getUser()[con.ID],
            [con.USER_LINE] : getCurrentLine(),
            [con.TRANSACTION] : transId,            
        }

        if(surrogateActive())
        finalAnnotation[con.SURROGATE_USER] = getSurrogateUser()[con.ID]

        let res = await axios.post(con.rest_base_url + this.getTransactionAnnotationApi(transactionType), finalAnnotation, config)
  
        return(res.data)           
    }



    // Support methods
    // -----------------

    static getTransactionApi = (transactionType) => {

        let staff = surrogateActive() ? con.STAFF_API : ""

        if(transactionType in this.TRANSACTION_API_MAP)
            return(con.transactions_api + staff + this.TRANSACTION_API_MAP[transactionType])

        throw new Error(`Unkown transaction type ${transactionType}`)

    }

    static getTransactionAnnotationApi = (transactionType) => {

        let staff =  surrogateActive() ? con.STAFF_API : ""

        if(transactionType in this.TRANSACTION_ANNOTATION_API_MAP)
            return(con.transactions_api +  staff + this.TRANSACTION_ANNOTATION_API_MAP[transactionType])

        throw new Error(`Unkown transaction type ${transactionType}`)

    }

}

/**
 * Legacy
 * Was created to enable offline logic but was never used (change of solution architecture)
 * 
 */
class OfflineLogic{

    // Remote Methods
    static async fetchTransactions(transactionType)
    {
        // By default returns empty array
        return([])           
    }

    static async addTransaction(transactionType, transactionValues){

        // Creates new ID and returns the element
        transactionValues[con.ID] = getNewTransactionId(transactionType)

        return(transactionValues)
        
    }

    static async addBulkTransactions(transactionType, transactionsValues){

        let id = getNewTransactionId(transactionType)
        transactionsValues.forEach(ob => {
            ob[con.ID] = id
            id++;
        });

        return(transactionsValues)
        
    }

    static async replaceTransactions(transactionType, transactionsValues){

        return(this.addBulkTransactions(transactionType, transactionsValues))
        
    }

    

    static async setTransaction(transactionType, id, transactionValues){

        // Returns the trasnaction as received with the new created annotation
        transactionValues[con.ID] = id 
        let res = {[con.TRANSACTION] : transactionValues,
                   [con.ANNOTATION] : createEditingAnnotation(id)}

        return(res)
    }

    static async deleteTransaction(transactionType, id){

        return(id)
        
    }

    // Annotation Methods
    // ------------------------
    static async fetchAnnotations(transactionType)
    {
        // Returns empty array
        return([])        
    }

    static async addAnnotation(transactionType)
    {
        return({})        
    }


}



