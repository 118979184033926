
// Server Config
import server_config from "./server_config.json"

// Constant for REST requests
export const rest_base_url =  server_config["api_server_url"] 
export const rest_base_url_local =  'http://127.0.0.1:8080'
export const rest_finance_url = server_config["api_finance_url"]
export const rest_finance_url_local = 'http://127.0.0.1:8000' 


// export const rest_finance_url = "http://127.0.0.1:8000/"
export const tk_finance = '720d5d574f33d0a59127a5ac948ff5f3cbacf140d7f3bda777988ab0880f1a40'
export const static_url =  rest_base_url + "/static"
export const server_check = "/server"

// Zone
export const BASE_ZONE = "America/Bogota"


//GENERAL
// Atomic Symbols
export const OK = "OK"
export const STATUS = "status"
export const MESSAGE = "MESSAGE"
export const LOADING = "LOADING"
export const NOT_STARTED = "NOT_STARTED"
export const WRONG_CREDENTIALS = "Wrong credentials"
export const ERROR = "ERROR"
export const UP = "UP"
export const DOWN = "DOWN"
export const NEUTRAL = "NEUTRAL"
export const FILE_NAME = "FILE_NAME"
export const GENERATING = "GENERATING"
export const CURRENCY_EPS = 5
export const ASCENDING = "ASCENDING"
export const DESCENDING = "DESCENDING"
export const TOTAL = "TOTAL"
export const DAYS = "DAYS"
export const SELECTED = "SELECTED"
export const BLANK = ""

// Initial SPOT
export const DEFAULT_SPOT = "default_spot"

// Value type constants
export const TEXT = "text" // For Text Input
export const NUMERIC = "numeric" // For Numeric (Float and Int) Input
export const DATE = "date" // For Date (Excluding Time of Day) Input
export const DATE_TIME = "date_time" // For Datetime (Including Time of Day) Input
export const MONEY = "money" // For Money (Any currency) Inputs
export const MONEY_MILLIONS = "money_millions" // For when money should be expressed in millions
export const MONEY_THOUSANDS = "money_thousands" // For when money should be expressed in thousands
export const PERCENTAGE = "percentage" // For Percentages Inputs
export const EMAIL = "email" // For email values
export const PASSWORD = "password" // For Password inputs
export const MULTIPLE_SELECTION = "multiple_selection" // For multiple selection inputs
export const BOOLEAN = "boolean" // For boolean values
export const SPOT_TYPE = "spot_type" // For Spot types input (Manual or Automatic)
export const ACTION_TYPE = "action" // For when the value is an action (button)
export const OBJECT = "object" // For when the input is an Object

// Money Types
export const MONEY_COP = "COP"
export const MONEY_USD = "USD"



// Constants for money representation
export const CURRENCY = "currency"
export const SUFFIX_COLOMBIAN_PESOS = " COP"
export const SUFFIX_DOLLARS = " USD"
export const SUFFIX_PERCENTAGE = "%"
export const MILLIONS_TEXT = "Millones"
export const THOUSANDS_TEXT = "Miles"
export const DENOMINATOR = "denominator"
export const ALLOW_DECIMALS = "allowDecimals"

// Constants for Percentage Represntation
export const DECIMAL_SCALE = "decimalScale"

export const MONEY_SUFFIXES = [SUFFIX_DOLLARS, SUFFIX_COLOMBIAN_PESOS]

// Constants for periodicity
export const DAYS_IN_YEAR = 252
export const DAYS_IN_MONTH = parseInt(Math.round(DAYS_IN_YEAR/12))

export const DAYS_IN_YEAR_FORWARDS = 365

export const PERIODICITY_DAY = 'DAY'
export const PERIODICITY_WEEK = 'WEEK'
export const PERIODICITY_MONTH = 'MONTH'

export const DATE_FORMAT = "yyyy-mm-dd"
export const INVALID_DATE = "Fecha Invalida"

export const ID = 'id'
export const VALUE = 'value'
export const VALUES = 'values'
export const TYPE = "type"
export const NAME = "name"
export const NAME_SHORT = "name_short" 
export const CODE = "code"
export const VAR = "var"
export const APPEND = "APPEND"
export const REPLACE = "REPLACE"
export const IGNORE = "IGNORE"
export const USERNAME = "username"
export const NEW_PASSWORD = "new_password"
export const USER_ID = "user_id"
export const LINES = "lines"
export const LINE = "line"
export const LINE_ID = "line_id"
export const LAST_EDITED = "last_edited"
export const PHONE_NUMBER = "phone_number"
export const PROFILE = "profile"
export const ICON = "icon"


// FINANCE URLS
export const get_all_market_data_url = "/quote/get_all_market_data"
export const historic_dollar_info_url = "/quote/get_historic_dollar_info"
export const market_summaries_url = "/quote/get_market_summaries"
// export const market_assets_url = "/quote/get_market_assets"
export const market_forward_curve_url = "/quote/get_forward_curve"
export const market_all_forward_curve_url = "/quote/get_all_forward_curves"
export const market_ibr_url = "/quote/get_ibr"
export const market_all_ibr_url = "/quote/get_all_ibr"
export const market_all_sofr_url = "/quote/get_all_sofr_curves"
export const market_volatility_surface_by_date_url = "/quote/get_volatility_surface_by_date"
export const spot_delay_url = "/quote/get_delay_spot"
export const intraday_dollar_info = "/quote/get_intraday_dollar_info"

// reports
export const reports_finance = '/reports'
export const forward_assessment_table_url = "/fwd_assessmet_table/"
export const forward_assessment_report_url = "/print_fwd_assessmet/"
export const option_assessment_info_url = "/option_assessmet_info/"
export const option_assessment_report_url = "/print_option_assessmet/"








// Login
export const login_api = "/users/api/auth/login"
export const logout_api = "/users/api/auth/logout"
export const refresh_token_api = "/users/api/auth/refresh_token"
export const check_password_api = "/users/api/auth/check_password"
export const change_password_api = "/users/api/auth/change_password"
export const staff_change_password_api = "/users/staff/api/auth/change_password"
export const user_activity_api = 'users/api/activity_register'

// User Profile
export const user_profile_api = '/users/api/profile'
export const staff_user_profile_api = '/users/staff/api/profile'

// User Line Application Parameters
export const user_line_application_parameters_api = '/users/api/user_line_application_parameters/'
export const staff_user_line_application_parameters_api = '/users/staff/api/user_line_application_parameters/'

// User Lines
export const user_lines_api = '/users/api/user_lines'
export const staff_user_lines_api = '/users/staff/api/user_lines'
export const user_line_api = '/users/api/user_line/'
export const staff_user_line_api = '/users/staff/api/user_line/'
export const staff_user_line_comments_api = '/users/staff/api/user_lines_comments'

// Surrogate list
export const surrogate_user_list_api = '/users/api/non_staff_users'


// Transactions
export const bulk = "bulk/"
export const transactions_api = "/transactions"
export const exposures_api = '/api/exposures/'
export const coverages_fwd_api = '/api/forward_coverages/'
export const coverages_spot_api = '/api/spot_coverages/'
export const coverage_option_api = '/api/option_coverages/'
export const accounts_api = '/api/accounts/'
export const usdcop_market_alerts = '/api/usdcop_market_alerts/'
export const usdcop_alerts_null_reached = `${usdcop_market_alerts}null-reached/`
export const usdcop_alerts_reached_not_notified = `${usdcop_market_alerts}reached-not-notified/`
export const usdcop_alerts_mark_notified = `${usdcop_market_alerts}mark-notified/`

//api
export const sse_api = "/sse_app/realtime_alerts/?token="

// Model Parameters
export const model_parameters_api = '/api/model_parameters/'

// Surrogate user Transactions
export const all_surrogate_user_model_parameters_api = '/staff/api/all_model_parameters'
export const all_surrogate_user_exposures_api = '/staff/api/all_exposures'
export const all_surrogate_user_coverages_fwd_api = '/staff/api/all_forward_coverages'
export const all_surrogate_user_coverages_spot_api = '/staff/api/all_spot_coverages'
export const all_surrogate_user_coverages_option_api = '/staff/api/all_option_coverages'
export const all_surrogate_user_accounts_api = '/staff/api/all_accounts'


export const SUMMARY_TABLE = "summary_table"
export const SUMMARY_ROW = "summary_row"


// Annotations
export const exposures_annotation_api = '/api/annotations/exposures/'
export const coverages_fwd_annotation_api = '/api/annotations/forward_coverages/'
export const coverages_spot_annotation_api = '/api/annotations/spot_coverages/'
export const coverages_option_annotation_api = '/api/annotations/option_coverages/'
export const accounts_annotation_api = '/api/annotations/accounts/'

// Reports
export const reports_api = '/reports'
export const purchase_reporte_url = "/api/generate_purchase_report/"
// export const forward_assessment_report_url = "/api/generate_forward_assessment_report/"
export const position_reporte_url = "/api/generate_position_report/"
export const committee_report_url = "/api/generate_committee_report/"
export const templates_api = reports_api + "/api/get_template/"
export const terms_of_use_api = reports_api + "/api/get_terms_of_use"
// export const option_assessment_report_url = "/api/generate_option_assessment_report/"



// Static reports Names
export const TERMS_OF_USE_NAME = "Terminos y Condiciones Vinco Markets.pdf"
export const SECURITY_SUMMARY_NAME = "Resumen Seguridad Vinco Markets.pdf"

// Market
// export const historic_dollar_info_url = "/market/api/get_historic_dollar_info"
// export const spot_delay_url = "/market/api/get_delay_spot"
// export const market_summaries_url = "/market/api/get_market_summaries"
// export const market_forward_curve_url = "/market/api/get_forward_curve"
// export const market_all_forward_curve_url = "/market/api/get_all_forward_curves"
// export const market_ibr_url = "/market/api/get_ibr"
// export const market_all_ibr_url = "/market/api/get_all_ibr"
// export const market_all_sofr_url = "/market/api/get_all_sofr_curves"
// export const market_volatility_surface_by_date_url = "/market/api/get_volatility_surface_by_date"
// export const get_all_market_data_url = "/market/api/get_all_market_data"
export const market_assets_url = "/market/api/get_market_assets"
export const quote_assets_url = "/market/api/quote_assets"
// Orion
export const orion_summary_url = "/market/api/get_orion_summary"
export const orion_betas_url = "/market/api/get_orion_betas"
export const levels_url = "/market/api/get_levels"

// Advisors
export const advisors_api = "/users/api/advisors"
export const add_advisors_api = "/users/api/add_advisor"
export const delete_advisors_api = "/users/api/delete_advisor"


// Surrogate API
export const STAFF_API = "/staff"


// Client Type
export const IMPORTER = 'IMPORTER'
export const EXPORTER = 'EXPORTER'
export const CLIENT_AVAILABLE_TYPES = [IMPORTER, EXPORTER]

// Authentication Constants
// --------------------------
export const IS_AUTHENTICATED = "isAuthenticated"
export const USER = "user"
export const SURROGATE_USER = "surrogate_user"
export const TOKEN = "token"
export const IS_STAFF = "is_staff"
export const NEEDS_TO_LOGIN_AGAIN = "needs_to_login_again"
export const ROLE = 'role'

export const MAX_MINUTES_SINCE_TOKEN_REFRESH = 120;
export const MAX_IDLE_TIME_MINUTES = 30;
export const ACTIVITY_INTERVAL_CHECK_MINUTES = 5;
export const INACTIVITY_PROGRESS_BAR_START_SECONDS = 60
export const INACTIVITY_PROGRESS_BAR_MILLISECONDS_CHECK = 100


// User Lines Constants
export const USER_LINE = "user_line"
export const COMMENTING_USER = "commenting_user"
export const COMMENTING_USER_USERNAME = "commenting_user_username"
export const DATE_CREATED = "date_created"

// Scenario Constants
// ------------------------
// Historic Prices
export const HISTORIC_PRICES_NAME = "Tasa de Cambio Histórica"
export const POLITIC_PERCENTAGE = 0.7
// Var Up
export const SCENARIO_VAR_UP = "SCENARIO_VAR_UP"
export const SCENARIO_VAR_UP_NAME = "VaR Up"

// Var Down
export const SCENARIO_VAR_DOWN = "SCENARIO_VAR_DOWN"
export const SCENARIO_VAR_DOWN_NAME = "VaR Down"

// Percentage
export const SCENARIO_PERCENTAGE = "SCENARIO_PERCENTAGE"
export const SCENARIO_PERCENTAGE_NAME = "Porcentaje"

// Bank of Republic
export const SCENARIO_BOR_MIN = "SCENARIO_BANK_OF_REPUBLIC_MIN"
export const SCENARIO_BOR_MIN_NAME = "Banco Rep. Mínimo"

export const SCENARIO_BOR_AVG = "SCENARIO_BANK_OF_REPUBLIC_AVG"
export const SCENARIO_BOR_AVG_NAME = "Banco Rep. Promedio"

export const SCENARIO_BOR_MAX = "SCENARIO_BANK_OF_REPUBLIC_MAX"
export const SCENARIO_BOR_MAX_NAME = "Banco Rep. Máximo"

// Vinco Scenario
export const SCENARIO_VINCO = "SCENARIO_VINCO"
export const SCENARIO_VINCO_NAME = "Vinco"
export const DEFAULT_VINCO_SCENARIO_VALUES = [4498.3,4730,4630,4850,4720,4620,4520,4420,4320,4220,4120,4020,3920]

// Custom
export const SCENARIO_CUSTOM = "SCENARIO_CUSTOM"
export const SCENARIO_CUSTOM_NAME = "Personalizado"

export const MONTHLY_VALUES = "monthly_values"


export const HISTORIC_SCENARIO_DAYS_TO_DISPLAY = 90

// Store constants
export const STORE = "store"
export const REDUCER_EXPOSURES = 'exposures'
export const REDUCER_FWD_COVERAGES = 'forward_coverages'
export const REDUCER_SPOT_COVERAGES = 'spot_coverages'
export const REDUCER_OPTION_COVERAGES = 'option_coverages'
export const REDUCER_ACCOUNTS = 'accounts'
export const REDUCER_EXPOSURES_ANNOTATIONS = 'exposures_annotations'
export const REDUCER_FWD_COVERAGES_ANNOTATIONS = 'forward_coverages_annotations'
export const REDUCER_SPOT_COVERAGES_ANNOTATIONS = 'spot_coverages_annotations'
export const REDUCER_OPTION_COVERAGES_ANNOTATIONS = 'option_coverages_annotations'
export const REDUCER_ACCOUNTS_ANNOTATIONS = 'accounts_annotations'
export const REDUCER_MODEL_PARAMETERS = 'model_parameters'
export const REDUCER_USER_LINE_APPLICATION_PARAMETERS = 'user_line_application_parameters'
export const REDUCER_USER_PROFILE = 'user_profile'
export const REDUCER_USER_LINES = 'user_lines'
export const REDUCER_SCENARIOS = 'scenarios'
export const REDUCER_AUTHENTICATION = 'authentication'
export const REDUCER_APPLICATION_PARAMETERS = "applicationParameters"
export const REDUCER_ERRORS = "errors"
export const REDUCER_SURROGATE_USERS_TRANSACTIONS = "surrogate_users_transactions"
export const REDUCER_ADVISORS = "advisors"
export const REDUCER_ADMIN_LINE_COMMENTS  = "admin_line_comments"
export const REDUCER_MARKET_VALUES = "market_values"
export const REDUCER_MARKET_ALERTS = 'market_alerts'


export const getTransactionReducer = (trs) => trs
export const getTransactionAnnotationReducer = (trs) => trs + "_annotations"

// User parameters
export const AVATAR_IMAGE = "avatar_image"
export const MAIN_ADVISOR = "main_advisor"
export const ADVISORS = "advisors"
export const INCLUDE_IN_ADVISOR_PANEL = "include_in_advisor_panel"
export const USER_SELECTED_ASSETS = "selected_assets"

// Advisor Api Call
export const ADVISOR = "advisor"
export const IS_MAIN_ADVISOR = "is_main_advisor"
export const ADVISOR_SAVED = "advisor_saved"
export const MAIN_ADVISOR_SAVED = "main_advisor_saved"

// Application Parameters
export const IS_MOBILE = "is_mobile"
export const IS_MOBILE_ENABLED = "is_mobile_enabled"
export const CHECK_PASSWORD = "check_password"
export const CHANGE_PASSWORD = "change_password"
export const SPOT_SEARCH_INTERVAL = "spot_search_interval"
export const LOGGED_IN = "logged_in"
export const SELECTED_SCENARIO = "selected_scenario"
export const SELECTED_LINE = "selected_line"
export const AUTOMATIC_BANDS = "automatic_bands"
export const CUSTOM_SCENARIO_ACTIVE = "custom_scenario_active"
export const CUSTOM_FORWARD_CURVE = "custom_forward_curve"
export const CUSTOM_FORWARD_CURVE_ACTIVE = "custom_forward_curve_active"
export const HAS_TRANSACTIONS = "has_transactions"
export const CURRENT_DATE = "current_date"
export const IS_OFFLINE = "is_offline"
export const SERVER = "server_status"
export const APP_READY = "app_ready"
export const SELECTED_TRANSACTIONS = "selected_transactions"
export const CURRENT_TRANSACTION_NAVIGATION_PAGE = "current_transaction_page"
export const TOTAL_TRANSACTIONS_DISPLAYED = "total_transactions_displayed"
export const ADDING_NEW_ROW = "adding_new_row"
export const UPLOADED_FILE = "uploaded_file"
export const UPLOADING_FILE = "uploading_file"
export const CAN_ADD_TRANSACTION = "can_add_transaction"
export const CAN_DELETE_ALL_TRANSACTIONS = "can_delete_all_transactions"
export const INCLUDE_ACTIVE = "include_active"
export const INCLUDE_EXPIRED = "include_include_expired"
export const INCLUDE_PREPAY = "include_include_prepay"
export const INCLUDE_SALES = "include_sales"
export const FILTER_FUN = "filter_fun"
export const FWD_ASSESSMENT_FILTER_FUN ="fwd_assessment_filter_fun"
export const SEARCH_FUN = "search_fun"
export const ADD_SPOT_COVERAGE_TO_COMPENSATION_ACCOUNT = "add_spot_coverage_to_compensation_account"
export const VISUALIZATION_PERIODICITY = "visualization_periodicity"
export const ONLY_NON_ZERO_ROWS = "only_non_zero_rows"
export const LOADING_CURRENT_TRANSACTIONS = "loading_current_transactions"
export const TRANSACTION_PREPAYED = "transaction_prepayed"
export const TRANSACTIONS_LOADED = "transactions_loaded"
export const SURROGATE_TRANSACTIONS_LOADED = "surrogate_transactions_loaded"
export const TRANSACTIONS_INSERTED = "transactions_inserted"
export const MODEL_PARAMETERS_SAVED = "model_parameters_saved"
export const MODEL_PARAMETERS_FETCHED = "model_parameters_fetched"
export const USER_LINE_APPLICATION_PARAMETERS_SAVED = "user_line_application_parameters_saved"
export const USER_LINE_APPLICATION_PARAMETERS_FETCHED = "user_line_application_parameters_fetched"
export const USER_LINE_SAVED = "user_line_saved"
export const USER_LINE_DELETED = "user_line_deleted"
export const USER_LINE_ADDED = "user_line_added"
export const USER_INFORMATION_SAVED = "user_information_saved"
export const USER_LINE_COMMENT_ADDED = "user_line_comment_added"
export const SELECTED_MARKET_ASSET = "selected_market_asset"
export const FORWARD_ASSESSMENT_START_DATE = "forward_assessment_start_date"
export const FORWARD_ASSESSMENT_REFERENCE_DATE = "forward_assessment_reference_date"
export const FORWARD_ASSESSMENT_REFERENCE_TRM = "forward_assessment_reference_trm"
export const FORWARD_ASSESSMENT_REFERENCE_NEXT_DAY_TRM = "forward_assessment_reference_next_day_trm"
export const FORWARD_ASSESSMENT_REFERENCE_RATE_CLOSE = "forward_assessment_reference_rate_close"
export const FORWARD_ASSESSMENT_RATE = "forward_assessment_rate"
export const FORWARD_ASSESSMENT_RATE_ORIGIN = "forward_assessment_rate_origin"
export const FORWARD_ASSESSMENT_FORWARD_CURVE = "forward_assessment_forward_curve"
export const FORWARD_ASSESSMENT_IBR = "forward_assessment_ibr"
export const FORWARD_ASSESSMENT_SHOW_HIDDEN_COLUMNS = "forward_assessment_show_hidden_columns"
export const FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES = "forward_assessment_show_expired_coverages"
export const FORWARD_ASSESSMENT_INCLUDE_PURCHASES = "forward_assessment_include_purchases"
export const FORWARD_ASSESSMENT_INCLUDE_SALES = "forward_assessment_include_sales"
export const OPTION_ASSESSMENT_START_DATE = "option_assessment_start_date"
export const OPTION_ASSESSMENT_RATE = "option_assessment_rate"
export const OPTION_ASSESSMENT_REFERENCE_DATE = "option_assessment_reference_date"
export const OPTION_ASSESSMENT_REFERENCE_TRM = "option_assessment_reference_trm"
export const OPTION_ASSESSMENT_REFERENCE_NEXT_DAY_TRM = "option_assessment_reference_next_day_trm"
export const OPTION_ASSESSMENT_REFERENCE_RATE_CLOSE = "option_assessment_reference_rate_close"
export const OPTION_ASSESSMENT_RATE_ORIGIN = "option_assessment_rate_origin"
export const OPTION_ASSESSMENT_SHOW_HIDDEN_COLUMNS = "option_assessment_show_hidden_columns"
export const OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES = "option_assessment_show_expired_coverages"
export const OPTION_ASSESSMENT_INCLUDE_PURCHASES = "option_assessment_include_purchases"
export const OPTION_ASSESSMENT_INCLUDE_SALES = "option_assessment_include_sales"
export const OPTION_ASSESSMENT_INCLUDE_CALL = "option_assessment_include_call"
export const OPTION_ASSESSMENT_INCLUDE_PUT = "option_assessment_include_put"
export const OPTION_ASSESSMENT_FILTER_FUN ="option_assessment_filter_fun"
export const ORION_SUMMARY_FETCHED = "orion_summary_fetched"
export const ORION_BETAS_FETCHED = "orion_betas_fetched"


export const ALL_TRANSACTIONS_LOADED = "all_transactions_loaded"
export const ALL_SURROGATE_TRANSACTIONS_LOADED = "all_surrogate_transactions_loaded"

// Market
export const MARKET_SUMMARIES = "market_summaries"
export const HISTORIC_DOLLAR_INFO = 'historic_dollar_info'
export const DATES = 'dates'
export const PRICES = 'prices'
export const MARKET_ASSETS = "market_assets"
export const ALL_FORWARD_CURVES = "all_forward_curves"
export const VOLATILITY = "volatility"
export const AVERAGE = "average"
export const ALL_IBR = "all_ibr"
export const TV_CODE = "tv_code"
export const MARKET_FORWARD_CURVE = "forward_curve"
export const MARKET_IBR = "ibr"
export const VOLATILITY_SURFACE = "volatility_surface"
export const VINCO_CODE = "vinco_code"
export const CODES = "codes"
export const ALL_SOFR_CURVES = "all_sofr_curves"
export const DELTAS = 'deltas'
export const VOLATILITIES = 'volatilities'
export const SURFACE = 'surface'


// Orion
export const ORION_SUMMARY = "orion_summary"
export const ORION_BETAS = "orion_betas"
export const ORION_REAL_RATE = "real_rate"
export const ORION_ESTIMATED_RATE = "estimated_rate"
export const ORION_SCORE = "score"
export const ORION_REPORT = "report"
export const ORION_BETAS_VALUES = "betas"
export const ORION_CUSTOM_BETAS = "custom"
export const ORION_MEAN = "mean"
export const ORION_STD = "std"
export const ORION_INTERCEPT = "intercept"
export const ORION_SIGNAL = "signal"

// Orion Custom Betas
export const CUSTOM_BETA_SPREAD = "spread"


export const ORION_SUMMARY_WINDOW_IN_DAYS = 360

// Orion Signal Values
export const ORION_SIGNAL_STRONG_SELL = "STRONG_SELL"
export const ORION_SIGNAL_SELL = "SELL"
export const ORION_SIGNAL_NEUTRAL = "NEUTRAL"
export const ORION_SIGNAL_BUY = "BUY"
export const ORION_SIGNAL_STRONG_BUY = "STRONG_BUY"

export const ORIONS_SIGNALS = [ORION_SIGNAL_STRONG_SELL, ORION_SIGNAL_SELL, ORION_SIGNAL_NEUTRAL, ORION_SIGNAL_BUY, ORION_SIGNAL_STRONG_BUY]
export const ORIONS_SIGNALS_VALUES = [0.25, 0.45, 0.55, 0.75] // Should be 1 element less than the signals


// Dollar Info
export const TRM_NEXT_DAY = "trm+1"
export const TRM = "trm"
export const OPEN = "open"
export const CLOSE = "close" 
export const HIGH = "high" 
export const LOW = "low" 


// Market days
export const MARKET_0_DAYS = "market_0_days"
export const MARKET_5_DAYS = "market_5_days"
export const MARKET_10_DAYS = "market_10_days"
export const MARKET_30_DAYS = "market_30_days"
export const MARKET_60_DAYS = "market_60_days"
export const MARKET_90_DAYS = "market_90_days"
export const MARKET_180_DAYS = "market_180_days"
export const MARKET_360_DAYS = "market_360_days"
export const MARKET_540_DAYS = "market_540_days"
export const MARKET_1080_DAYS = "market_1080_days"
export const MARKET_TOTAL_DAYS = "market_total_days"

export const DEFAULT_MARKET_PERIOD = MARKET_360_DAYS

export const FORWARD_CURVE_DAYS = [MARKET_0_DAYS,MARKET_30_DAYS, MARKET_60_DAYS, MARKET_90_DAYS, MARKET_180_DAYS, MARKET_360_DAYS, MARKET_540_DAYS]
export const IBR_DAYS = [MARKET_0_DAYS,MARKET_30_DAYS, MARKET_90_DAYS, MARKET_180_DAYS, MARKET_360_DAYS, MARKET_1080_DAYS]
export const SOFR_DAYS = [MARKET_0_DAYS,MARKET_30_DAYS, MARKET_90_DAYS, MARKET_180_DAYS]




// Coverages Grid
export const LEVELS_FETCHED = "levels_fetched"
export const LEVELS = "levels"
export const LEVEL = "level"
export const COVERAGES_GRID_CLIENT_TYPE = "coverages_grid_client_type"
export const COVERAGES_GRID_STOP_LOSS = "coverages_grid_stop_loss"
export const COVERAGES_GRID_INITIAL_LEVEL = "coverages_grid_initial_level"
export const COVERAGES_GRID_FINAL_LEVEL = "coverages_grid_final_level"
export const COVERAGES_GRID_SPREAD = "coverages_grid_spread"
export const COVERAGES_GRID_DESIRED_BAND = "coverages_grid_desired_band"
export const COVERAGES_GRID_AMOUNT_TO_BUY = "coverages_grid_amount_to_buy"
export const COVERAGE_PERCENTAGE_ACCUMULATED = "coverage_percentage_accumulated"





export const SPOT_DIRECTION = "spot_direction"
export const SELECTED_BAND = "selected_band"
export const SELECTED_VAR = "selected_var"
export const INTERNAL_TRANSACTION_CHANGE = "internal_transaction_change"
export const CARRY_OVER_COVERAGE = "carry_over_coverage"

// Model Application Parameters
export const MODEL_APPLICATION_PARAMETERS = [VISUALIZATION_PERIODICITY, CARRY_OVER_COVERAGE]



// Model Parameter Constants
export const CLIENT_TYPE = "client_type"
export const PERIODICITY = "periodicity"
export const MID_BAND_VALUES = "mid_band_values"
export const BAND_WIDTH = "band_width"
export const TERM_PERIODICITY = "term_periodicity"
export const TERM_IN_MONTHS = "term_in_months"
export const AVERAGE_FOR_PERCENTAGE = "average_for_percentage"
export const INITIAL_COVERAGE = "initial_coverage"
export const GRADIENT = "gradient"
export const SPOT = "spot"
export const SPOT_SCHEME = "spot_scheme"
export const LAST_SPOT_UPDATE = "last_spot_update"
export const MARKET_VOLATILITY = "market_volatility"
export const MARKET_AVERAGES = "market_averages"
export const ESC_CONFIDENCE_LEVEL =  "esc_confidence_level"
export const ESC_PERCENTUAL_CHANGE =  "esc_percentual_change"
export const PASSTHROUGH = "passthrough"
export const CUSTOM_SCENARIO_VALUES = "custom_scenario_values"
export const SPREAD = "spread"
export const SELECTED_BAND_COMPARISON_SCENARIOS = "selected_band_comparison_scenarios"




// Spot Scheme constants
export const AUTOMATIC = "AUTOMATIC"
export const MANUAL = "MANUAL"


// Band Constants
export const LOWER_BAND = "LOWER_BAND"
export const MID_BAND = "MID_BAND"
export const HIGHER_BAND = "HIGHER_BAND"
export const MID_LOW_BAND = "MID_LOW_BAND"
export const MID_HIGH_BAND = "MID_HIGH_BAND"
export const BANDS = [LOWER_BAND,MID_LOW_BAND,MID_BAND,MID_HIGH_BAND,HIGHER_BAND]

export const POSITION_IN_BANDS_ARRAY = ['Fuera Banda Baja',
                                    'Banda Baja',
                                    'Banda Media Baja',
                                    'Banda Media',
                                    'Banda Media Alta',
                                    'Banda Alta',
                                    'Fuera Banda Alta',
                                    ]




// Numeric constants
export const MILLION = 1000000
export const THOUSAND = 1000

// SPOT Extraction
export const USDCOP = 'USDCOP=X'
export const SPOT_INTERVAL_CHECK_MS = 60000;



// Pages Index
export const PAGE_START = "/"
export const PAGE_SCENARIOS = "/parametros/escenarios"
export const PAGE_BANDS = "/parametros/bandas"
export const PAGE_COVERAGE_PROFILE = "/parametros/perfil_cobertura"
export const PAGE_MARKETS_TECHNICAL_ANALYSIS = "/mercados/analisis_tecnico"
export const PAGE_MARKETS_FUNDAMENTAL_ANALYSIS = "/mercados/analisis_fundamental"
export const PAGE_HISTORICAL_DATA = "/mercados/datos_historicos";
export const PAGE_INTRADAY_DOLLAR_INFO = "/mercados/info_intraday_dolar";
export const PAGE_MARKET_ORION_OVERVIEW = "/resumen_orion"
export const PAGE_INPUTS = "/ingreso_flujos"
export const PAGE_CURRENT_POSITION = "/resultados/posicion_actual"
export const PAGE_RISK_COMPARISON = "/resultados/comparacion_riesgo"
export const PAGE_BAND_POSITION = "/resultados/posicion_en_bandas"
export const PAGE_BAND_COMPARISON = "/resultados/comparacion_bandas"
export const PAGE_REPORTS = "/reportes"
export const PAGE_CALCULATOR = "/calculadora"
export const PAGE_ADMIN_DASHBOARD = "/panel_de_control"
export const PAGE_USER_PROFILE = "/perfil"
export const PAGE_COVERAGES_GRID = "/grilla_coberturas"
export const PAGE_FORWARD_ASSESSMENT = "/valoracion_forwards"
export const PAGE_OPTION_ASSESSMENT = "/valoracion_opciones"


// Model Parameters
export const MODEL_PARAMETERS = "model_parameters"

// Transaction name
export const TRANSACTION = "transaction"
export const TRANSACTION_TYPE = "transaction_type" 
export const EXPOSURES = "exposures"
export const COVERAGES_FWD = "forward_coverages"
export const COVERAGES_SPOT = "spot_coverages"
export const COVERAGES_OPTION = "option_coverages"
export const ACCOUNTS = "accounts"
export const COMMENTS = "comments"
export const EXPIRING_TRANSACTION_TYPES = [EXPOSURES, COVERAGES_FWD, COVERAGES_OPTION]
export const MARKET_ALERTS = 'market_alerts'

// Annotations
export const ANNOTATION = "annotation"
export const TRANSACTION_ID = "transaction"
export const ACCOUNT_ANNOTATION = "account_annotation"

// Transaction columns
export const EXPIRATION_DATE = "expiration_date"
export const AMOUNT = "amount"
export const COMMENT = "comment"
export const STATE = "state"
export const OPENING_DATE = "opening_date"
export const RATE = "rate"
export const FWD_COUNTER_RATE = "forward_counter_rate"
export const COUNTER_COVERAGE_PERCENTAGE = "counter_coverage_percentage"
export const COUNTERPARTY = "counterparty"
export const OPENING_TRM = "opening_trm"
export const OPENING_SPOT = "opening_spot"
export const COVERAGE_TYPE = "coverage_type"
export const SPOT_COVERAGE_TYPE = "spot_coverage_type"
export const ACCOUNT_TYPE = "account_type"
export const OPTION_TYPE = "option_type"
export const STRIKE = "strike"
export const PREMIUM = "premium"
export const COVERAGES = 'coverages'

// Transaction Summary Columns
export const PERIOD = "period"
export const EFFECTIVE_RATE = "effective_rate"
export const EXPOSURE_AMOUNT = "exposure_amount"
export const COVERAGE_AMOUNT = "coverage_amount"
export const COUNTER_COVERAGE_AMOUNT = "counter_coverage_amount"
export const FWD_COVERAGE_AMOUNT = "forward_coverage_amount"
export const FWD_COVERAGE_RATE = "forward_coverage_rate"
export const OPTION_COVERAGE_AMOUNT = "option_coverage_amount"
export const OPTION_COVERAGE_RATE = "option_coverage_rate"
export const DIRECT_CALL_AMOUNT = "direct_call_amount"
export const COUNTER_BUY_CALL_AMOUNT = "counter_buy_call_amount"
export const COUNTER_SELL_CALL_AMOUNT = "counter_sell_call_amount"
export const DIRECT_PUT_AMOUNT = "direct_put_amount"
export const COUNTER_BUY_PUT_AMOUNT = "counter_buy_put_amount"
export const COUNTER_SELL_PUT_AMOUNT = "counter_sell_put_amount"
export const DIRECT_CALL_DELTA_AMOUNT = "direct_call_delta_amount"
export const COUNTER_BUY_CALL_DELTA_AMOUNT = "counter_buy_call_delta_amount"
export const COUNTER_SELL_CALL_DELTA_AMOUNT = "counter_sell_call_delta_amount"
export const DIRECT_PUT_DELTA_AMOUNT = "direct_put_delta_amount"
export const COUNTER_BUY_PUT_DELTA_AMOUNT = "counter_buy_put_delta_amount"
export const COUNTER_SELL_PUT_DELTA_AMOUNT = "counter_sell_put_delta_amount"
export const DIRECT_CALL_STRIKE = "direct_call_strike"
export const COUNTER_BUY_CALL_STRIKE = "counter_buy_call_strike"
export const COUNTER_SELL_CALL_STRIKE = "counter_sell_call_strike"
export const DIRECT_PUT_STRIKE = "direct_put_strike"
export const COUNTER_BUY_PUT_STRIKE = "counter_buy_put_strike"
export const COUNTER_SELL_PUT_STRIKE = "counter_sell_put_strike"
export const DIRECT_CALL_SETTLEMENT_RATE = "direct_call_settlement_rate"
export const COUNTER_BUY_CALL_SETTLEMENT_RATE = "counter_buy_call_settlement_rate"
export const COUNTER_SELL_CALL_SETTLEMENT_RATE = "counter_sell_call_settlement_rate"
export const DIRECT_PUT_SETTLEMENT_RATE = "direct_put_settlement_rate"
export const COUNTER_BUY_PUT_SETTLEMENT_RATE = "counter_buy_put_settlement_rate"
export const COUNTER_SELL_PUT_SETTLEMENT_RATE = "counter_sell_put_settlement_rate"
export const EXECUTION_DIRECT_CALL_VAR = 'execution_direct_call_var'
export const EXECUTION_DIRECT_PUT_VAR = 'execution_direct_put_var'
export const EXECUTION_COUNTER_BUY_CALL_VAR = 'execution_counter_buy_call_var'
export const EXECUTION_COUNTER_SELL_CALL_VAR = 'execution_counter_sell_call_var'
export const EXECUTION_COUNTER_BUY_PUT_VAR = 'execution_counter_buy_put_var'
export const EXECUTION_COUNTER_SELL_PUT_VAR = 'execution_counter_sell_put_var'
export const FWD_COUNTER_COVERAGE_AMOUNT = "forward_counter_coverage_amount"
export const VAR_RATE = "var_rate"
export const MAX_VAR_RATE = "max_var_rate"
export const NET_EXPOSURE_AMOUNT = "net_exposure_amount"
export const COVERAGE_PERCENTAGE = "coverage_percentage"
export const VAR_X_USD = "var_x_usd"
export const COUNTER_VAR_X_USD = "counter_var_x_usd"
export const POSITION_IN_BANDS = "position_in_bands"
export const POSITION_IN_BANDS_DATA = "position_in_bands_data"
export const EXPIRY_DAYS = "expiry_days"
export const VIEW_COMMENTS = "view_comments"
export const OBJECTIVE_BAND_PERCENTAGE = "objective_band_percentage"
export const OBJECTIVE_BAND = "objective_band"
export const PERCENTAGES = "percentages"
export const PERCENTAGE_TO_REACH = "percentage_to_reach"
export const AMOUNT_TO_REACH = "amount_to_reach"
export const REAL_POSITION = "real_position"
export const ALL_AMOUNTS = 'all_amounts'


export const TRANSACTIONS_SUMMARY_COLUMNS = [DATE,
                                             EXPOSURE_AMOUNT,
                                             COVERAGE_AMOUNT,
                                             RATE,
                                             VAR_RATE,
                                             NET_EXPOSURE_AMOUNT,
                                             COVERAGE_PERCENTAGE,
                                             EFFECTIVE_RATE,
                                             VAR_X_USD,
                                             VAR]
// Adds Bands
BANDS.forEach((b) => TRANSACTIONS_SUMMARY_COLUMNS.push(`${b}_${VAR}`))


export const TRANSACTION_SUMMARY = "transactions_summary"

// Coverage Type
export const BUY = "buy"
export const SELL = "sell"
export const CASH_REGISTER = "cash_register"

// Option Type
export const CALL = "call"
export const PUT = "put"

// Transaction State
export const ACTIVE = "active"
export const EXPIRED = "overdue"
export const PREPAYED = "prepayed"

// Account Type
export const COMPENSATION_ACCOUNT = "compensation_account"
export const NATURAL_COVERAGE = "natural_coverage"

// Transactions WorkBook Sheets names
export const EXPOSURES_SHEET_NAME = "Exposiciones"
export const COVERAGES_FWD_SHEET_NAME = "Coberturas Forward"
export const COVERAGES_OPTIONS_SHEET_NAME = "Coberturas Opciones"
export const COVERAGES_SPOT_SHEET_NAME_IMPORTER = "Coberturas SPOT"
export const COVERAGES_SPOT_SHEET_NAME_EXPORTER = "Monetizaciones"
export const ACCOUNTS_SHEET_NAME = "Cuentas"

// Counterparties in coverage
export const BANCOLOMBIA = "bancolombia"
export const DAVIVIENDA = "davivienda"
export const BOGOTA = "b_bogota"
export const SCOTIABANK = "scotiabank"
export const ITAU = "itau"
export const CORFICOLOMBIANA = "corficolombiana"
export const OCCIDENTE = "b_occidente"
export const BBVA = "bbva"
export const SANTANDER = "santander"
export const SUDAMERIS = "sudameris"

export const COUNTERPARTIES = [BLANK, BANCOLOMBIA, DAVIVIENDA, BOGOTA, SCOTIABANK, ITAU, CORFICOLOMBIANA, OCCIDENTE, BBVA, SANTANDER, SUDAMERIS]

    
// Transactions Colum Order
export const EXPOSURES_EXPORT_COLUMNS = [{ [ID] : EXPIRATION_DATE, [NAME] : "Fecha Vencimiento", [TYPE]: DATE}, 
                                          {[ID] : AMOUNT,  [NAME] : "Exposicion", [TYPE]: MONEY_USD},
                                          {[ID] : OPENING_TRM,  [NAME] : "TRM Apertura", [TYPE]: MONEY_COP}, 
                                          {[ID] : COMMENT,[NAME] : "Comentario", [TYPE] :TEXT}]
export const COVERAGES_FWD_EXPORT_COLUMNS = [{[ID] : OPENING_DATE, [NAME] : "Fecha Apertura", [TYPE]: DATE}, 
                                             {[ID] : EXPIRATION_DATE, [NAME] : "Fecha Vencimiento", [TYPE]: DATE}, 
                                             {[ID] :AMOUNT, [NAME] : "Monto Cubierto", [TYPE]: MONEY_USD}, 
                                             {[ID] : OPENING_SPOT, [NAME] : "Spot Apertura", [TYPE]: MONEY_COP}, 
                                             {[ID] : RATE, [NAME] : "Tasa Forward", [TYPE]: MONEY_USD}, 
                                             {[ID] : COVERAGE_TYPE, [NAME] : "Tipo", [TYPE]: TEXT},
                                             {[ID] : COUNTERPARTY, [NAME] : "Contraparte", [TYPE]: TEXT},
                                             {[ID] : COMMENT, [NAME] : "Comentario", [TYPE]: TEXT}]
export const COVERAGES_SPOT_EXPORT_COLUMNS_IMPORTER = [{[ID]: OPENING_DATE, [NAME] : "Fecha Apertura", [TYPE]: DATE}, 
                                                       {[ID] : AMOUNT,  [NAME] : "Monto", [TYPE]: MONEY_USD}, 
                                                       {[ID] : RATE, [NAME] : "Tasa Compra", [TYPE]: MONEY_COP}, 
                                                       {[ID] : COMMENT,  [NAME] : "Comentario", [TYPE]: TEXT}]
export const COVERAGES_SPOT_EXPORT_COLUMNS_EXPORTER = [{[ID]: OPENING_DATE, [NAME] : "Fecha Apertura", [TYPE]: DATE}, 
                                                       {[ID] : AMOUNT,  [NAME] : "Monto", [TYPE]: MONEY_USD}, 
                                                       {[ID] : RATE, [NAME] : "Tasa Venta", [TYPE]: MONEY_COP}, 
                                                       {[ID] : COMMENT,  [NAME] : "Comentario", [TYPE]: TEXT}]     
export const COVERAGES_OPTION_EXPORT_COLUMNS = [{[ID] : OPENING_DATE, [NAME] : "Fecha Apertura", [TYPE]: DATE}, 
                                                {[ID] : EXPIRATION_DATE, [NAME] : "Fecha Vencimiento", [TYPE]: DATE}, 
                                                {[ID] : AMOUNT, [NAME] : "Monto Cubierto", [TYPE]: MONEY_USD}, 
                                                {[ID] : OPENING_SPOT, [NAME] : "Spot Apertura", [TYPE]: MONEY_COP}, 
                                                {[ID] : STRIKE, [NAME] : "Strike", [TYPE]: MONEY_COP},
                                                {[ID] : PREMIUM, [NAME] : "Prima", [TYPE]: MONEY_COP},
                                                {[ID] : OPTION_TYPE, [NAME] : "Opción", [TYPE]: TEXT}, 
                                                {[ID] : COVERAGE_TYPE, [NAME] : "Tipo", [TYPE]: TEXT},
                                                {[ID] : COUNTERPARTY, [NAME] : "Contraparte", [TYPE]: TEXT},
                                                {[ID] : COMMENT, [NAME] : "Comentario", [TYPE]: TEXT}]                                         
export const ACCOUNTS_EXPORT_COLUMNS = [{[ID] : ACCOUNT_TYPE, [NAME] : "Tipo", [TYPE]: TEXT}, 
                                        {[ID]: AMOUNT, [NAME] : "Monto", [TYPE]: MONEY_USD},
                                        {[ID] : COMMENT,  [NAME] : "Comentario", [TYPE]: TEXT}]



export const EXPOSURES_COLUMN_ORDER = EXPOSURES_EXPORT_COLUMNS.map(col => col[ID])
export const COVERAGES_FWD_COLUMN_ORDER = COVERAGES_FWD_EXPORT_COLUMNS.map(col => col[ID])
export const COVERAGES_OPTIONS_COLUMN_ORDER = COVERAGES_OPTION_EXPORT_COLUMNS.map(col => col[ID])
export const COVERAGES_SPOT_COLUMN_ORDER = COVERAGES_SPOT_EXPORT_COLUMNS_IMPORTER.map(col => col[ID]) // Both Exporter and Importer have the  same order
export const ACCOUNTS_COLUMN_ORDER = ACCOUNTS_EXPORT_COLUMNS.map(col => col[ID])


// Prepaying Constants
export const PREPAY_EXPOSURE = "prepay_exposure"
export const PREPAY_AMOUNT = "prepay_amount"
export const PREPAY_METHOD = "prepay_method"
export const PREPAYMENT_RATE = "prepay_rate"
export const PREPAY_WITH_ACCOUNT = "prepay_with_account"
export const PREPAY_WITH_COVERAGE = "prepay_with_coverage"
export const PREPAY_EXPOSURE_ANNOTATION = "prepay_exposure_annotation"

// Forward Assessment Column Constants
export const DEVALUATION_VALUE = "devaluation_value"
export const DEVALUATION_FWD_SPREAD_BUY_SELL = 0.0015
export const NEW_FORWARD_RATE = "new_forward_rate"
export const FORWARD_RATE_DIFFERENCE = "forward_rate_difference"
export const FORWARD_RATE_DIFFERENCE_PRESENT_VALUE = "forward_rate_difference_present_value"
export const DISCOUNT_RATE = "discount_rate"
export const ASSESSMENT = "assessment"

// Forward Assessment Summary Constants
export const ACTIVE_FWD_AMOUNT = "active_forwards_amount"
export const AVERAGE_FWD_RATE = "average_fwd_rate"
export const ASSESSMENT_RATE = "assessment_rate"
export const PORTFOLIO_ASSESSMENT = "portfolio_assessment"

// Assessment min date
export const MIN_ASSESSMENT_DATE = "2022-06-28"

// Option Assessment Column Constants
export const EXPIRE_YEARS = 'expire_years'
export const FREE_RISK_RATE = "free_risk_rate"
export const FOREIGN_RATE = 'foreign_rate'
export const OPTION_D1_HIST = 'option_d1_historical'
export const OPTION_D2_HIST = 'option_d2_historical'
export const OPTION_N_D1_HIST = 'option_N(d1)_historical'
export const OPTION_N_D2_HIST = 'option_N(d2)_historical'
export const OPTION_N_NEG_D1_HIST = 'option_N(-d1)_historical'
export const OPTION_N_NEG_D2_HIST = 'option_N(-d2)_historical'
export const DELTA_CALL = 'delta_call'
export const DELTA_PUT = 'delta_put'
export const DELTA = 'delta'
export const MIN_DELTA = 'min_delta'
export const MAX_DELTA = 'max_delta'
export const VOLATILITY_CURVE = 'volatility_curve'
export const OPTION_D1_VOL = 'option_d1_volatility'
export const OPTION_D2_VOL = 'option_d2_volatility'
export const OPTION_N_D1_VOL = 'option_N(d1)_volatility'
export const OPTION_N_D2_VOL = 'option_N(d2)_volatility'
export const OPTION_VALUE = 'option_value'
export const PREMIUM_BALANCE = 'premium_balance'
export const PYG_OPTION_X_DOLLAR = 'pyg_option_x_dollar'
export const DIRECT_DELTA = 'direct_delta'
export const COUNTER_DELTA = 'counter_delta'


// Option Assessment Summary Constants
export const ACTIVE_OPTION_AMOUNT = "active_option_amount"
export const PREMIUM_GAINS = "premium_gains"
export const TOTAL_ASSESSMENT = 'total_assessment'


// Forward Calculator Constants
// --------------------------------
export const FORWARD_CALCULATOR_FORWARD_CURVE = "forward_calculator_forward_curve"

// Editable
export const CALCULATOR_INSTRUMENT_TYPE = "instrument_type"
export const CALCULATOR_EXECUTION_DATE = "execution_date"
export const CALCULATOR_EXPIRY_DAYS = "expiry_days"
export const CALCULATOR_EXPIRATION_DATE = "expiration_date"
export const CALCULATOR_NOMINAL = "nominal"
export const CALCULATOR_BANK_SPOT = "bank_spot"
export const CALCULATOR_QUOTED_DEVALUATION = "quoted_devaluation"
export const CALCULATOR_QUOTED_FORWARD_RATE = "quoted_fwd_rate"
export const CALCULATOR_LOCAL_SPOT = "local_spot"
export const CALCULATOR_REFERENCE_DEVALUATION = "reference_devaluation"
export const CALCULATOR_REFERENCE_FORWARD_RATE = "reference_forward_rate"
export const CALCULATOR_LOCAL_FORWARD_CURVE = "local_forward_curve"



// Non editable
export const CALCULATOR_LOCAL_SPREAD = "local_spread"
export const CALCULATOR_FWD_POINTS = "fwd_points"
export const CALCULATOR_FWD_POINT_DIFF = "fwd_point_diff"
export const CALCULATOR_COP_DEVALUATION_DIFF = "cop_devaluation_diff"
export const CALCULATOR_TOTAL_COP_DIFF = "total_cop_diff"
export const CALCULATOR_P_AND_G = "p_and_g"
export const CALCULATOR_SPOT_DIFF = "spot_diff"

// Parameters
export const CALCULATOR_EDITING_DAYS = "editing_days"
export const CALCULATOR_EDITING_QUOTED_FORWARD = "editing_quoted_forward"

// Levels
export const STAFF = "staff"
export const CLIENT = "client"
export const VIEWER = "viewer"

// Api Constants
export const BULK_CREATE = "bulk_create"
export const REPLACE_ALL = "replace_all"
export const DELETE_ALL = "delete_all"
export const SPOT_COVERAGE = "spot_coverage"
export const DATA = "data"
export const COMMANDS = "commands"

// Report Data Constants
export const FORWARD_ASSESSMENT_REPORT = "forward_assessment_report"
export const OPTION_ASSESSMENT_REPORT = "option_assessment_report"
export const REPORT_FILE_NAME = "report_file_name"
export const START_DATE = "start_date"
export const END_DATE = "end_date"
export const INCLUDE_PURCHASES = "include_purchases"
export const OPERATION = "operation"
export const SUMMARY = "summary"
export const PARAMETERS = "parameters"

export const BAND_SUMMARY_REPORT = "band_summary_report"
export const CURRENT_FLOW_REPORT = "current_flow_report"
export const PURCHASE_REPORT = "purchase_report"
export const POSITION_REPORT = "position_report"
export const COMMITTEE_REPORT = "committee_report"
export const ASSESSMENT_FWD_REPORT = "assesment_forward_report"
export const DEFAULT_SELECT_FIELD = "default_select_field"

export const AVAILABLE_REPORTS = {
                                  [DEFAULT_SELECT_FIELD]: {
                                    [NAME] : "---------Seleccione---------",
                                    [STAFF] : false},
                                  [POSITION_REPORT]: {
                                    [NAME] : "Reporte Posición Actual",
                                    [STAFF] : false},
                                  [BAND_SUMMARY_REPORT]: {
                                    [NAME] : "Resumen Banda",
                                    [STAFF] : false},
                                  [CURRENT_FLOW_REPORT]: {
                                    [NAME] : "Flujos Actuales",
                                    [STAFF] : false},
                                  [PURCHASE_REPORT]: {
                                    [NAME] : "Reporte de Operaciones",
                                    [STAFF] : false},
                                  [ASSESSMENT_FWD_REPORT]: {
                                    [NAME] : "Valoración de Forwards",
                                    [STAFF] : false},                   
                                  [COMMITTEE_REPORT]: {
                                    [NAME] : "Comité",
                                    [STAFF] : true}   // OCULTAR REPORTE TRUE
                                 }



// Template names
export const TEMPLATE_NAME = "template_name"
export const INPUT_TEMPLATE_NAME_IMPORTER = "formato_flujos_imp_vincomk.xlsx"
export const INPUT_TEMPLATE_NAME_EXPORTER = "formato_flujos_exp_vincomk.xlsx"


// Surrogate User Lists
export const SURROGATE_USER_DICT = "surrogate_user_dict"

// Market Assets Constants
export const MARKET_ASSETS_USDCOP = "FX_IDC:USDCOP"
export const COP_ASSET = {[ID] : 27, [VINCO_CODE]: "COP", [TV_CODE]: MARKET_ASSETS_USDCOP, [NAME_SHORT] : "COP",[NAME] : "Peso Colombiano"}
export const US10Y_ASSET = "US10Y"
export const CO10Y_ASSET = "CO10Y"


// Interface Smoothing
export const UI_TIMEOUT = 10
export const LOADING_TRANSACION_TIMEOUT = 500

// Value Changes
export const VALUE_CHANGE_TIMEOUT = 2000

// Table sorting
export const TABLE_SORTING_TIME = 100
export const MIN_ROWS_FOR_LOADING = 50

// Max font
export const MAX_FONT = 1.8
export const MIN_FONT = 1.2


// Mobile fonts
// 8px is added to this value (vw)
export const MOBILE_MAX_FONT = 0.4
export const MOBILE_MIN_FONT = 0.1

// Dates
export const SHOULD_DISABLE_DATE = "shouldDisableDate"
export const DateFormatString = {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    weekDaysLong: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miercoles',
      'Jueves',
      'Viernes',
      'Sabado'
    ],
    weekDaysShort: [
      'Do',
      'Lu',
      'Ma',
      'Mi',
      'Ju',
      'Vi',
      'Sa'
    ]
  }




// Screeen sizes ment fot VH
export const NAV_IMAGE_HEIGHT = 30
export const NAV_BAR = 13.6
export const TICKER_TAPE = 6.5
export const USABLE_SCREEN_SIZE = 100 -  NAV_BAR - TICKER_TAPE


// Screeen sizes ment fot VH
export const MOBILE_NAV_IMAGE_HEIGHT = 23
export const MOBILE_NAV_BAR = 21
export const MOBILE_TICKER_TAPE = 0
export const MOBILE_USABLE_SCREEN_SIZE = 100 -  MOBILE_NAV_BAR - MOBILE_TICKER_TAPE


// Errors
export const LATEST_ERROR = "latest_error"
export const SESSION_ERRORS = "session_errors"


// Messages
export const NO_TRANSACTIONS_MESSAGE = "No tiene exposiciones ni coberturas activas."


// Scenarios
export const GENERATING_FUNCTION = "gen_fun"

// Max length
export const MAX_COMMENT_LENGTH = 300

// Search
export const DEFAULT_SEARCH_FUNCTION = (_) => true


// Pagination
export const MAX_ROWS_PER_PAGE = 50

// Mobile
export const MOBILE_MAX_WIDTH = 900
export const MOBILE_MAX_HEIGHT = 500

// Lines
export const NEW_LINE_NAME = "Nueva"
export const MAX_LINES_PER_USER = 3

// Notifications
export const NOTIFICATION_TIME_LONG = 4000
export const NOTIFICATION_TIME_SHORT = 3000

// Column Variables
export const DEFAULT_VALUE = 'DEFAULT_VALUE'
export const EDITABLE = "editable"
export const COMPRESS = "compress"
export const SET_VALUE = "set_value"
export const ON_BLUR = "onBlur"
export const VALUE_TRANSLATOR = "valueTranslator"
export const OPTIONS = "options"
export const VALUE_CHECKER = "value_checker"


// Uploading Constants
export const PARSED_RECORD_STATUS = "parsed_record_status"
export const PARSED_ERROR_CODES = "parsed_error_codes"
export const ROW_NUMBER = '__rowNum__'

// Errors
export const PARSING_ERROR_CODE_MISSING_AMOUNT = "parsing_error_missing_amount"
export const PARSING_ERROR_CODE_MISSING_OPENING_SPOT = "parsing_error_missing_opening_spot"
export const PARSING_ERROR_CODE_MISSING_STRIKE = "parsing_error_missing_strike"
export const PARSING_ERROR_CODE_MISSING_PREMIUM = "parsing_error_missing_premium"
export const PARSING_ERROR_CODE_MISSING_DATE = "parsing_error_missing_date"
export const PARSING_ERROR_CODE_DATE_WRONG_FORMAT = "parsing_error_date_wrong_format"
export const PARSING_ERROR_CODE_MISSING_RATE = "parsing_error_missing_rate"
export const PARSING_ERROR_CODE_AMOUNT_WRONG_FORMAT = "parsing_error_amount_wrong_format"
export const PARSING_ERROR_CODE_OPENING_SPOT_WRONG_FORMAT = "parsing_error_opening_spot_wrong_format"
export const PARSING_ERROR_CODE_RATE_WRONG_FORMAT = "parsing_error_rate_wrong_format"
export const PARSING_ERROR_CODE_STRIKE_WRONG_FORMAT = "parsing_error_strike_wrong_format"
export const PARSING_ERROR_CODE_PREMIUM_WRONG_FORMAT = "parsing_error_premium_wrong_format"







export const PARSING_ERROR_MESSAGES = {
    [PARSING_ERROR_CODE_MISSING_AMOUNT] : "Falta el monto.",
    [PARSING_ERROR_CODE_MISSING_OPENING_SPOT] : "Falta el SPOT de apertura.",
    [PARSING_ERROR_CODE_MISSING_DATE] : "Falta la fecha.",
    [PARSING_ERROR_CODE_MISSING_RATE] : "Falta la tasa",
    [PARSING_ERROR_CODE_MISSING_STRIKE] : "Falta el Strike",
    [PARSING_ERROR_CODE_MISSING_PREMIUM] : "Falta la Prima",
    [PARSING_ERROR_CODE_DATE_WRONG_FORMAT] : "Fecha en formato equivocado. Ejemplo formato esperado: 2023-06-26",
    [PARSING_ERROR_CODE_AMOUNT_WRONG_FORMAT] : "El monto no está en formato número",
    [PARSING_ERROR_CODE_OPENING_SPOT_WRONG_FORMAT] : "El SPOT de apertura no está en formato número",
    [PARSING_ERROR_CODE_RATE_WRONG_FORMAT] : "La tasa no está en formato número",
    [PARSING_ERROR_CODE_STRIKE_WRONG_FORMAT] : "El Strike no está en formato número",
    [PARSING_ERROR_CODE_PREMIUM_WRONG_FORMAT] : "La Prima no está en formato número",





}

// Config Default Parameters
export const DEFAULT_MODEL_PARAMETERS = "default_model_parameters"
export const DEFAULT_USER_LINE_APPLICATION_PARAMETERS = "default_user_line_application_parameters"
export const DEFAULT_COVERAGE_GRID_PARAMETERS = "default_coverages_grid_parameters"


// Local Storage
export const LOCAL_LAST_INTERACTION = "local_last_interaction"
export const LOCAL_LAST_TOKEN_REFRESHED_TIMESTAMP = "local_last_token_refreshed_timestamp"
export const LOCAL_USER = "local_user"
export const LOCAL_TOKEN = "local_token"


// Report Names
export const TRANSACTIONS_SHEET_NAME = "Flujos"
export const TRANSACTIONS_SUMMARY_SHEET_NAME = "Resumen"
export const FORWARD_ASSESSMENT_SHEET_NAME = "Resumen Val"
export const FORWARD_ASSESSMENT_REPORT_NAME = "Reporte Valoracion"
export const PURCHASE_REPORT_NAME = "Reporte Compras"
export const POSITION_REPORT_NAME = "Reporte Posicion Actual"
export const OPTION_ASSESSMENT_SHEET_NAME = "Resumen Val Opt."


// Export type
export const XLSX_FILE_TYPE = ".xlsx"
export const PDF_FILE_TYPE = ".pdf"
export const PPTX_FILE_TYPE = ".pptx"

export const MAX_EXCEL_SHEET_NAME = 31



// Placeholders
export const CLIENT_PLACEHOLDER = "Cliente"


// Navigation Constants
export const NAV_SINGLE = "single"
export const NAV_MULTIPLE = "multiple"
export const NAV_TYPE = "type"
export const NAV_TEXT = "text"
export const NAV_MENU_TEXTS = "linkTexts"
export const NAV_MENU_TEXT = "menuText"
export const NAV_ICON = "icon"
export const NAV_MENU_ICON = "menuIcon"
export const NAV_PAGE_ID = "pageID"
export const NAV_MENU_PAGES_ID = "linkPages"
export const NAV_LEVEL = "level"
export const NAV_EXCLUDE = "exclude"
export const NAV_INCLUDE_MOBILE = "include_mobile"
export const NAV_INCLUDE_PAGES_MOBILE = "include_pages_mobile"

export const NAVIGATION_PAGES_ARRAY = [
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "globe-network",
      [NAV_TEXT]: "Inicio",
      [NAV_PAGE_ID]: PAGE_START,
      [NAV_INCLUDE_MOBILE]: true
  },
  {
      [NAV_TYPE]: NAV_MULTIPLE,
      [NAV_MENU_ICON]: "settings",
      [NAV_MENU_TEXT]: "Parámetros",
      [NAV_INCLUDE_MOBILE]: false,
      [NAV_MENU_TEXTS]: [`Info. ${CLIENT_PLACEHOLDER}`, "Escenarios"],
      [NAV_MENU_PAGES_ID]: [PAGE_COVERAGE_PROFILE, PAGE_SCENARIOS],
      [NAV_EXCLUDE] : VIEWER
  },
  {
      [NAV_TYPE]: NAV_MULTIPLE,
      [NAV_MENU_ICON]: "trending-up",
      [NAV_MENU_TEXT]: "Mercado",
      [NAV_INCLUDE_MOBILE]: true,
      [NAV_MENU_TEXTS]: [
          "Análisis Técnico",
          "Análisis Fundamental",
          "Datos Históricos",
          "Info Intraday Dólar"
          // "Resumen Orion" // OCULTAR Orion
      ],
      [NAV_MENU_PAGES_ID]: [
          PAGE_MARKETS_TECHNICAL_ANALYSIS,
          PAGE_MARKETS_FUNDAMENTAL_ANALYSIS,
          PAGE_HISTORICAL_DATA,
          PAGE_INTRADAY_DOLLAR_INFO 
          // PAGE_MARKET_ORION_OVERVIEW // OCULTAR Orion
      ],
      [NAV_INCLUDE_PAGES_MOBILE]: [true, true, true, true]
  },
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "upload",
      [NAV_TEXT]: "Entradas",
      [NAV_PAGE_ID]: PAGE_INPUTS,
      [NAV_EXCLUDE] : VIEWER

  },
  {
      [NAV_TYPE]: NAV_MULTIPLE,
      [NAV_MENU_ICON]: "chart",
      [NAV_MENU_TEXT]: "Resultados",
      [NAV_INCLUDE_MOBILE]: true,
      [NAV_MENU_TEXTS]: [
          "Posición Actual",
          "Comparación Valor en Riesgo",
          "Posición en Bandas",
          "Comparación Bandas"
      ],
      [NAV_MENU_PAGES_ID]: [
          PAGE_CURRENT_POSITION,
          PAGE_RISK_COMPARISON,
          PAGE_BAND_POSITION,
          PAGE_BAND_COMPARISON
      ],
      [NAV_INCLUDE_PAGES_MOBILE]: [true, false, false, false]
  },
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "heat-grid",
      [NAV_TEXT]: "Grilla",
      [NAV_PAGE_ID]: PAGE_COVERAGES_GRID,
      [NAV_INCLUDE_MOBILE]: true // OCULTAR GRILLA
  },
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "manual",
      [NAV_TEXT]: "Reportes",
      [NAV_PAGE_ID]: PAGE_REPORTS,
      [NAV_INCLUDE_MOBILE]: true
  },
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "calculator",
      [NAV_TEXT]: "Calculadora",
      [NAV_PAGE_ID]: PAGE_CALCULATOR,
      [NAV_INCLUDE_MOBILE]: true
  },
  // {
  //     [NAV_TYPE]: NAV_MULTIPLE,
  //     [NAV_MENU_ICON]: "comparison",
  //     [NAV_MENU_TEXT]: "Valoración",
  //     [NAV_INCLUDE_MOBILE]: true, // OCULTAR VALORACION
  //     [NAV_MENU_TEXTS]: [
  //         "Forwards",
  //         "Opciones"
  //     ],
  //     [NAV_MENU_PAGES_ID]: [
  //         PAGE_FORWARD_ASSESSMENT,
  //         PAGE_OPTION_ASSESSMENT
  //     ],
  //     [NAV_INCLUDE_PAGES_MOBILE]: [true, true]
  // },
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "comparison",
      [NAV_TEXT]: "Valoración",
      [NAV_PAGE_ID]: PAGE_FORWARD_ASSESSMENT,
      [NAV_INCLUDE_MOBILE]: true // OCULTAR VALORACION
  },
  {
      [NAV_TYPE]: NAV_SINGLE,
      [NAV_ICON]: "dashboard",
      [NAV_TEXT]: "Panel",
      [NAV_PAGE_ID]: PAGE_ADMIN_DASHBOARD,
      [NAV_LEVEL]: STAFF
  }
]

